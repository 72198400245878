import * as React from 'react';
import type { HeadFC } from 'gatsby';
import '../global-styles/index.scss';
import Header from '../components/header/header';
import Hero from '../components/hero/hero';
import MethodsList from '../components/methods';
import Footer from '../components/footer/footer';
import Invitation from '../components/invitation';
import PhotoSteps from '../components/photo-steps';
import { Helmet } from 'react-helmet';
// import { GatsbySeo } from 'gatsby-plugin-next-seo';
import background from '../assets/background-1.webp';
import PhotoBlock from '../components/photo-block/photo-block';
import sim from '../assets/personal-photo.webp';
import RouterBlock from '../components/router-block';

const blockConfig = {
	imgUrl: sim,
	imgAlt: 'Simona Grigaraitė',
	navigationLabel: 'Daugiau',
	navigationUrl: '/about',
	backgroundColor: 'var(--c-near-white)',
	isDark: false
};

const IndexPage = () => {
	setTimeout(() => {
		document.querySelector('main')!.className = 'index-page';
	}, 100);
	return (
		<main className="index-page is-hidden">
			<Helmet
				htmlAttributes={{
					lang: 'lt'
				}}>
				<title>Psichoterapija | Simona Grigaraitė</title>
				<meta name="description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
				<meta name="image" content={background} />
				<meta name="og:description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
				<meta name="og:image" content={background} />
			</Helmet>
			<Header></Header>
			<Hero></Hero>
			<PhotoBlock {...blockConfig}>
				<p className="blog-paragraph special-paragraph">
					<span>Esu gydytoja psichiatrė, </span>
					<br />
					<span>individualios psichodinaminės psichoterapijos kandidatė.</span>
				</p>
			</PhotoBlock>
			<MethodsList></MethodsList>
			<RouterBlock></RouterBlock>
			<PhotoSteps></PhotoSteps>
			<Invitation></Invitation>
			<Footer></Footer>
		</main>
	);
};

export default IndexPage;

export const Head: HeadFC = () => (
	<>
		<title>Psichoterapija | Simona Grigaraitė</title>
		<meta name="description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="image" content={background} />
		<meta name="og:description" content="Terapeutės Simonos Grigairatės asmeninė internetinė svetainė" />
		<meta name="og:image" content={background} />
	</>
);
