import * as React from 'react';
import PhotoBlock, { PhotoBlockModel } from './photo-block/photo-block';

import rysio_pradzia from '../assets/rysio-pradzia.webp';
import terapijos_procesas from '../assets/terapijos-procesas.webp';
import svarbu_zinoti from '../assets/svarbu-zinoti.webp';

const blocks: PhotoBlockModel[] = [
	{
		imgUrl: terapijos_procesas,
		imgAlt: '',
		title: 'Psichoterapijos procesas',
		subScript: `Sesijos vyksta 1-2 kartus per savaitę, pastoviu, iš anksto numatytu laiku. Vienos sesijos trukmė - 50 minučių. Galimi gyvi ar nuotoliniai susitikimai. Psichoterapijos trukmė kiekvienam žmogui yra individuali, paprastai trunka ne mažiau nei kelerius metus. Viso proceso metu laikomasi konfidencialumo principo.`,
		backgroundColor: 'white'
	},
	{
		imgUrl: rysio_pradzia,
		imgAlt: '',
		title: 'Terapinio ryšio pradžia',
		subScript: `Pirmąsias keletą sesijų vyksta susipažinimas, problemos bei pagalbos galimybių išsigryninimas, gyvenimo istorijos aptarimas. Tolimesni susitikimai vyksta abipusiu sutarimu.`,
		isInverted: true,
		backgroundColor: 'white'
	},
	{
		imgUrl: svarbu_zinoti,
		imgAlt: '',
		title: 'Svarbu žinoti',
		subScript: `Negaliu teikti psichoterapijos paslaugų asmeniškai pažįstamiems žmonėms. Negalėsiu padėti, jei esate priklausomas nuo psichoaktyviųjų medžiagų, lošimų ar kt. Taip pat, kai išgyvenate aktyvią psichozę ar manote, kad visi jūsų sunkumai kyla tik dėl išorinių veiksnių.`,
		backgroundColor: 'white'
	}
];

const PhotoSteps = (props: any) => {
	return (
		<div className="photo-steps">
			{blocks.map((block) => (
				<PhotoBlock {...block} key={block.title}></PhotoBlock>
			))}
		</div>
	);
};

export default PhotoSteps;
