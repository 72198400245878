import * as React from 'react';
import './listingHorizontal.scss';

const ListingHorizontal = (props: any) => {
	return (
		<section className="listing-horizontal" style={{ backgroundColor: props.backColor || 'white' }}>
			<div className="main-wrapper">
				<p className="listing-horizontal__super">{props.superScript}</p>
				<h2 className="listing-horizontal__title">{props.title}</h2>
				<ul>
					{props?.list?.map((item: any) => (
						<li className="listing-item" key={item.title}>
							<img src={item.image} alt={item.title} loading="lazy" />
							<h3 className="listing-item__title">{item.title}</h3>
							<p className="listing-item__desc">{item.description}</p>
						</li>
					))}
				</ul>
			</div>
		</section>
	);
};

export default ListingHorizontal;
