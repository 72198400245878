import { Link, navigate } from 'gatsby';
import * as React from 'react';
import LinkButton from '../linkButton/linkButton';
import './hero.scss';
import background from '../../assets/background-1.webp';

const Hero = () => {
	return (
		<div className="hero">
			<img className="hero__background" src={background} alt="" />
			<div className="main-wrapper">
				<div className="info-block">
					{/* <p className="info-block__super-title">Psichoterapija</p> */}
					<h1 className="info-block__title">
						<span>Simona</span>
						<span>Grigaraitė</span>
					</h1>
					<p className="info-block__sub-title">Individuali psichodinaminė psichoterapija.</p>
					<LinkButton className="info-block__btn-book" toUrl="/book">
						Teirautis dėl susitikimo
					</LinkButton>
				</div>
			</div>
		</div>
	);
};

export default Hero;
