import * as React from 'react';
import PhotoBlock, { PhotoBlockModel } from './photo-block/photo-block';
import pasirupink from '../assets/pasirupink.webp';

const blockConfig: PhotoBlockModel = {
	imgUrl: pasirupink,
	imgAlt: '',
	title: 'Pasirūpinkite savimi',
	subScript: `Tik išjautus tai, kas neišjausta, išgyvenus, tai, kas neišgyventa, žmogus tampa tikruoju savimi: laisvas, lankstus, kūrybiškas.`,
	navigationLabel: 'Teirautis dėl susitikimo',
	navigationUrl: '/book',
	isInverted: true,
	backgroundColor: 'var(--c-near-white)',
	isDark: false
};

const Invitation = (props: any) => {
	return <PhotoBlock {...blockConfig}></PhotoBlock>;
};

export default Invitation;
