import classNames from 'classnames';
import * as React from 'react';
import LinkButton from '../linkButton/linkButton';
import './photo-block.scss';

const PhotoBlock = (props: any) => {
	const btnClass = classNames({
		'photo-block': true,
		'is-inverted': props.isInverted,
		'is-dark': props.isDark
	});

	return (
		<div className={btnClass} style={{ backgroundColor: props.backgroundColor || 'unset' }}>
			<div className="main-wrapper">
				{props.imgUrl ? (
					<div className="photo-block__photo" id={props.imgUrl}>
						<img
							src={props.imgUrl}
							alt={props.imgAlt}
							loading={props.loading || 'lazy'}
							onLoad={() => {
								document.getElementById(props.imgUrl)?.classList.add('is-loaded');
							}}
						/>
					</div>
				) : null}
				<div className="photo-block__text">
					{props.superScript ? <p className="photo-block__super">{props.superScript}</p> : null}
					{props.title ? <h2 className="photo-block__title">{props.title}</h2> : null}
					{props.subScript ? <p className="photo-block__sub">{props.subScript}</p> : null}
					{props.children}
					{props.navigationUrl ? (
						<LinkButton className="photo-block__btn" toUrl={props.navigationUrl}>
							{props.navigationLabel || 'Learn more'}
						</LinkButton>
					) : null}
				</div>
			</div>
		</div>
	);
};

export interface PhotoBlockModel {
	imgUrl?: string;
	imgAlt: string;
	superScript?: string;
	subScript?: string;
	title?: string;
	navigationUrl?: string;
	navigationLabel?: string;
	isInverted?: boolean;
	isDark?: boolean;
	backgroundColor?: string;
	loading?: 'lazy' | 'eager';
}

export default PhotoBlock;
