import { Link } from 'gatsby';
import * as React from 'react';
import './linkButton.scss';

const LinkButton = (props: any) => {
	return (
		<React.Fragment>
			{!props.isButton ? (
				<Link to={props.toUrl} className="link-button" activeClassName="is-active">
					{props.children || 'link-button'}
				</Link>
			) : null}
			{props.isButton ? (
				<button
					className="link-button"
					onClick={props.clickFunction}
					disabled={props.disabled}
					tabIndex={0}
					aria-label={props.children || 'link-button'}>
					{props.children || 'link-button'}
				</button>
			) : null}
		</React.Fragment>
	);
};

export default LinkButton;
