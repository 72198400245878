import * as React from 'react';
import ListingHorizontal from './listingHorizontal/listingHorizontal';
import leaf from '../assets/leaf.svg';

const list = [
	{
		title: 'Vargina santykių problemos',
		description: `Pastebite vis pasikartojančias, nemalonias situacijas socialiniame gyvenime.`,
		image: leaf
	},
	{
		title: 'Svyruoja savivertė',
		description: `Išgyvenate liūdesio, savigraužos, tuštumos jausmus.`,
		image: leaf
	},
	{
		title: 'Kamuoja nerimas',
		description: `Patiriate sunkumus tvarkantis su kasdieniais rūpesčiais.`,
		image: leaf
	},
	{
		title: 'Išgyvenate krizę',
		description: `Susiduriate su netektimi, liga ar kitu gyvenimo lūžiu.`,
		image: leaf
	}
];

const MethodsList = (props: any) => {
	return <ListingHorizontal title={'Kada gali padėti psichoterapija?'} list={list} backColor={props.backColor}></ListingHorizontal>;
};

export default MethodsList;
