import * as React from 'react';
import LinkButton from './linkButton/linkButton';

const RouterBlock = (props: any) => {
	return (
		<div className="phone-block router-block">
			<div className="main-wrapper">
				<LinkButton className="info-block__btn-book" toUrl="/book">
					Teirautis dėl susitikimo
				</LinkButton>
			</div>
		</div>
	);
};

export default RouterBlock;
